import React from 'react'
import { Link as GLink } from 'gatsby'
import { Link, Text } from 'theme-ui'
import TextList from '@components/TextList'
import PageTitle from '@components/PageTitle'

const styles = {
  item: {
    display: `inline-block`
  }
}

export const PostHead = ({ title, author, date, timeToRead, category }) => {
  const dataSeparada = date?.split(' ')

  const traducaoMeses = {
    January: 'Janeiro',
    February: 'Fevereiro',
    March: 'Março',
    April: 'Abril',
    May: 'Maio',
    June: 'Junho',
    July: 'Julho',
    August: 'Agosto',
    September: 'Setembro',
    October: 'Outubro',
    November: 'Novembro',
    December: 'Dezembro'
  }

  const info = (
    <TextList>
      {author && author.slug && (
        <Text sx={styles.item}>
          {`Por `}
          <Link variant='mute' as={GLink} to={author.slug}>
            <strong>{author.name}</strong>
          </Link>
        </Text>
      )}
      {category && category.slug && (
        <Text sx={styles.item}>
          {`Categoria `}
          <Link variant='mute' as={GLink} to={category.slug}>
            <strong>{category.name}</strong>
          </Link>
        </Text>
      )}
      {date && (
        <Text sx={styles.item}>
          {dataSeparada[1].substring(0, 2) +
            ' ' +
            traducaoMeses[dataSeparada[0]] +
            ' ' +
            dataSeparada[2]}
        </Text>
      )}
      {timeToRead && (
        <Text sx={{ ...styles.item, color: `readTime` }}>
          <strong>Tempo de leitura: {timeToRead} min</strong>
        </Text>
      )}
    </TextList>
  )

  return <PageTitle header={title} running={info} />
}
